<template>
    <div>
        <h4 class="bold text-uppercase">Nạp tiền</h4>
        <div class="row">
            <div :class="panel == 'card' ? 'col-md-12' : 'col-md-8'">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            @click="handleShowPanel('user')"
                            id="home-tab"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            >Nạp tiền bằng chuyển khoản</a
                        >
                    </li>
                    <li v-if="dataAPI && dataAPI.status" class="nav-item">
                        <a
                            class="nav-link"
                            @click="handleShowPanel('card')"
                            id="card-tab"
                            data-toggle="tab"
                            href="#card"
                            role="tab"
                            aria-controls="card"
                            aria-selected="true"
                            >Nạp tiền bằng thẻ cào</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            @click="handleShowPanel('trainscoin')"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                            >Chuyển tiền</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            @click="handleShowPanel('history')"
                            id="history-tab"
                            data-toggle="tab"
                            href="#history"
                            role="tab"
                            aria-controls="history"
                            aria-selected="false"
                            >Lịch sử nạp tiền</a
                        >
                    </li>
                </ul>
                <div class="tab-content mt-3" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <h6 class="mb-3">Tỷ giá: 1 VND = 1 {{ currency }}</h6>
                        <h6 class="mb-3">
                            Bạn vui lòng chuyển khoản chính xác nội dung chuyển khoản bên dưới hệ thống sẽ tự động cộng
                            tiền cho bạn sau 1 phút sau khi nhận được tiền. Nếu chuyển khác ngân hàng sẽ mất thời gian
                            lâu hơn, tùy thời gian xử lý của mỗi ngân hàng Nếu sau 10 phút từ khi tiền trong tài khoản
                            của bạn bị trừ mà bạn vẫn chưa được cộng tiền vui lòng nhấn vào đây để liên hệ hỗ trợ
                        </h6>
                        <div
                            v-if="(user.role == 'admin' && !site.payment) || site.payment === '[]'"
                            class="alert alert-cyan p-3 pl-3 text-left"
                        >
                            <h6>
                                <i class="fa fa-info"></i>
                                Trang web của bạn hiện chưa cập nhật thông tin thanh toán, vui lòng cập nhật thông tin
                                thanh toán tại <a href="/site-config"><code>Cài đặt trang</code></a>
                            </h6>
                        </div>
                        <div v-else>
                            <div class="form-group row" v-for="(account, index) in paymentInfo" :key="index">
                                <label class="col-sm-4 col-form-label bold">Tài khoản số: {{ index + 1 }}</label>
                                <div class="col-sm">
                                    <div class="card card-table">
                                        <div class="card-body">
                                            <h6 v-if="account.name">
                                                Ngân hàng: <span class="bold">{{ account.name }}</span>
                                            </h6>
                                            <h6 v-if="account.account_name">
                                                Chủ TK: <span class="bold">{{ account.account_name }}</span>
                                            </h6>
                                            <h6 v-if="account.account_number">
                                                STK: <span class="bold">{{ account.account_number }}</span>
                                            </h6>
                                            <h6 v-if="account.branch">
                                                Chi nhánh: <span class="bold">{{ account.branch }}</span>
                                            </h6>
                                             <h6 v-if="account.qr">
                                                <img class="image-responsive" width="100%" :src="account.qr" />
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="config.payment_syntax" class="form-group row">
                                <label class="col-sm-4 col-form-label bold">Nội dung chuyển khoản:</label>
                                <div class="col-sm">
                                    <div class="card card-orange">
                                        <div class="card-body text-center">
                                            <h4 class="bold mb-0">{{ payment_syntax }}</h4>
                                            <button
                                                @click="copyPayment"
                                                type="button"
                                                class="btn btn-dark-blue btn-sm mt-2 font-13"
                                            >
                                                <i class="far fa-copy mr-1"></i>
                                                Copy
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="dataAPI && dataAPI.status"
                        class="tab-pane fade"
                        id="card"
                        role="tabpanel"
                        aria-labelledby="card-tab"
                    >
                        <div class="row">
                            <div class="col-md-6">
                                <h6 class="bold">Nạp thẻ</h6>
                                <div class="card card-white">
                                    <div class="card-body">
                                        <h6 class="mb-3">
                                            Tỷ giá: Nạp thẻ 100,000 VND =
                                            {{ (100000 - (dataAPI.charge * 100000) / 100) | formatNumber }}
                                            {{ currency }}
                                        </h6>
                                        <h6 class="font-13 bold red">
                                            - Nạp sai mệnh giá hoặc sai loại thẻ cào sẽ mất toàn bộ số tiền đã nạp.
                                        </h6>
                                        <h6 class="font-13 text-justify">
                                            - Khi nạp thẻ cào, bạn sẽ phải trả phí {{ dataAPI.charge }}% giá trị của thẻ
                                            để chi trả cho phí dịch vụ của nhà mạng.
                                        </h6>
                                        <h6 class="font-13 text-justify">
                                            - Bạn nạp thẻ 100,000 sẽ mất
                                            {{ ((dataAPI.charge * 100000) / 100) | formatNumber }} phí dịch vụ và thực
                                            nhận của tài khoản bạn là
                                            {{ (((100 - dataAPI.charge) * 100000) / 100) | formatNumber }} tương ứng với
                                            {{ (((100 - dataAPI.charge) * 100000) / 100) | formatNumber }}
                                            {{ currency }}
                                        </h6>
                                        <hr />
                                        <form action="" @submit.stop.prevent="sedPaymentCard">
                                            <div class="form-group">
                                                <label class="font-bold">Chọn nhà mạng</label>
                                                <select required v-model="card_info.card" class="form-control">
                                                    <option
                                                        v-for="(card, index) in dataAPI.card"
                                                        :key="index"
                                                        :value="card.card"
                                                        >{{ card.name }}</option
                                                    >
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label class="font-bold">Chọn mệnh giá</label>
                                                <select required v-model.number="card_info.amount" class="form-control">
                                                    <option
                                                        v-for="(card, index) in selectCard.amount"
                                                        :key="index"
                                                        :value="card"
                                                        >{{ card | formatNumber }} VND</option
                                                    >
                                                </select>
                                            </div>
                                            <div class="form-group mt-3">
                                                <label class="font-bold">Nhập Số Seri</label>
                                                <input
                                                    v-model="card_info.serial"
                                                    required="required"
                                                    type="number"
                                                    placeholder="Nhập số Seri"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label class="font-bold">Nhập Mã thẻ</label>
                                                <input
                                                    v-model="card_info.pin"
                                                    required="required"
                                                    type="number"
                                                    placeholder="Nhập mã thẻ"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group mb-0">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        v-model="checkConfirm"
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        id="customCheck1"
                                                    />
                                                    <label class="custom-control-label bold" for="customCheck1"
                                                        >Xác nhận nhập chính xác thông tin thẻ.
                                                        <span class="red"
                                                            >Nếu nhập sai mệnh giá hoặc sai loại thẻ cào sẽ mất toàn bộ
                                                            số tiền đã nạp.</span
                                                        ></label
                                                    >
                                                    <button
                                                        type="submit"
                                                        class="btn mt-4 btn-lg form-control bold btn-dark-blue"
                                                    >
                                                        Xác nhận
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h6 class="bold">Lịch sử nạp thẻ</h6>
                                <datatable :columns="columnsCard" :rows="dataCard"></datatable>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <h6 class="mb-3">Tính năng chuyển tiền cho tài khoản khác trong hệ thống</h6>
                        <form class="" @submit.stop.prevent="transCoin">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label font-bold">Tài khoản người nhận:</label>
                                <div class="col-sm-8">
                                    <input
                                        class="form-control input-light"
                                        type="text"
                                        placeholder="Nhập username người nhận"
                                        required=""
                                        v-model="to_user"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label font-bold">Số tiền cần chuyển:</label>
                                <div class="col-sm-8">
                                    <input
                                        class="form-control input-light"
                                        type="number"
                                        min="1"
                                        placeholder="Nhập số tiền cần chuyển"
                                        required=""
                                        v-model="quantity"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label font-bold">Ghi chú:</label>
                                <div class="col-sm-8">
                                    <input
                                        class="form-control input-light"
                                        type="text"
                                        placeholder="Nhập nội dung ghi chú"
                                        required=""
                                        v-model="descriptionTrans"
                                    />
                                    <button class="btn mt-3 form-control bold btn-dark-blue" type="submit">
                                        Chuyển tiền
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                        <datatable :columns="columns" :rows="rows"></datatable>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-4" v-if="panel != 'card'">
                <div class="card card-profile">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <h6 class="bold mb-0 font-16"><i class="fas fa-wallet mr-2"></i>Số tiền hiện có</h6>
                            </div>
                            <div class="col">
                                <h6 class="text-right bold font-19 mb-0">
                                    {{ user.coin | formatNumber }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-profile mt-4">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <h6 class="bold mb-0 font-16"><i class="fas fa-sign-in-alt mr-2"></i>Số tiền đã nạp</h6>
                            </div>
                            <div class="col">
                                <h6 class="text-right bold font-19 mb-0">
                                    {{
                                        user.coin > user.total_recharge ? user.coin : user.total_recharge | formatNumber
                                    }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="payment_note" class="card card-orange mt-4">
                    <div class="card-body">
                        <h6 class="bold font-14">
                            Hướng dẫn nạp tiền:
                        </h6>
                        <h6 v-html="payment_note" class="mb-0"></h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getPaymentLogs, userTransCoin, getPaymentCardConfig, sedPaymentCard, getPaymentCard } from "../../api/payment"
import { catchError } from "../../helpers"
import datatable from "../../components/datatable/datatable"
export default {
    name: "client-manager",
    components: { datatable },
    data() {
        return {
            panel: "",
            paymentInfo: {},
            to_user: "",
            quantity: 0,
            descriptionTrans: "",
            rows: [],
            columns: [
                {
                    label: "STT",
                    field: "index"
                },
                {
                    label: "ID",
                    field: "object_id"
                },
                {
                    label: "Số Tiền",
                    field: "amount_html",
                    html: true
                },
                {
                    label: "Nội Dung Chuyển Khoản",
                    field: "notes_html",
                    html: true
                },
                {
                    label: "Thời Gian",
                    field: "created_at",
                    timeago: true
                },
                {
                    label: "Trạng Thái",
                    field: "status_html",
                    html: true
                }
            ],
            checkConfirm: false,
            dataAPI: null,
            card_info: {
                card: "VTT",
                amount: "10000",
                serial: "",
                pin: ""
            },
            dataCard: [],
            columnsCard: [
                {
                    label: "Username",
                    field: "username"
                },
                {
                    label: "Loại thẻ",
                    field: "card_name"
                },
                {
                    label: "Số Tiền",
                    field: "amount",
                    numeric: true
                },
                {
                    label: "Số serial",
                    field: "serial",
                    html: true
                },
                {
                    label: "Mã thẻ",
                    field: "pin",
                    html: true
                },
                {
                    label: "Trạng Thái",
                    field: "status_html",
                    html: true
                },
                {
                    label: "Thời Gian",
                    field: "created_at",
                    timeago: true
                }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        config() {
            return this.site.config
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        },
        payment_syntax() {
            if (this.config.payment_syntax) {
                let syntax = this.config.payment_syntax
                let username = this.user.username
                return syntax.replace("{username}", username)
            } else return null
        },
        payment_note() {
            if (this.config.payment_note) {
                let note = this.config.payment_note
                return note.replace(/\n/g, "<br>")
            } else return null
        },
        selectCard() {
            return this.dataAPI.card.find(item => item.card == this.card_info.card)
        }
    },
    created() {
        this.getPaymentInfo()
        this.getPaymentCardConfig()
    },
    methods: {
        async getPaymentCardConfig() {
            let data = await getPaymentCardConfig()
            if (data.data) {
                this.dataAPI = data.data
            }
        },
        async getPaymentCard() {
            let data = await getPaymentCard()
            if (data.data) {
                this.dataCard = data.data.map(card => {
                    card.color = card.status < 0 ? true : false
                    card.status_html =
                        parseInt(card.status) === 1
                            ? `<span class='badge badge-pill btn-lg badge-success'>${card.description}</span>`
                            : parseInt(card.status) === 0
                            ? `<span class='badge badge-pill badge-warning'>${card.description}</span>`
                            : `<span class='badge badge-pill badge-danger'>${card.description}</span>`
                    return card
                })
            }
        },
        async sedPaymentCard() {
            if (!this.checkConfirm) {
                this.$swal("Lỗi", "Bạn chưa xác nhận nhập chính xác thông tin thẻ cào!", "error")
                return
            }
            let data = await sedPaymentCard(this.card_info)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", data.message, "success")
                this.getPaymentCard()
            } else this.$swal("Lỗi", catchError(data), "error")
        },
        copyPayment() {
            this.$copyText(this.payment_syntax)
            // eslint-disable-next-line no-undef
            this.$toastr.success("Đã copy nội dung chuyển khoản!")
        },
        getPaymentcards: async function() {
            let data = await getPaymentLogs()
            if (data.status === 200 && data.success) {
                this.rows = data.data.map((log, index) => {
                    log.index = index + 1
                    log.object_id = log.object_id ? log.object_id : log.username
                    log.notes_html =
                        '<input style="min-width:100px" type="text" class="form-control" value="' +
                        log.notes.replace(/"/g, "'") +
                        '">'
                    log.amount_html = `<h5 class='text-success bold'>${(
                        Number(log.new_coin) - Number(log.old_coin)
                    ).toLocaleString("it-IT")}</h5>`
                    log.status_html =
                        parseInt(log.status) === 1
                            ? `<span class='badge badge-pill btn-lg badge-success'>Thành công</span>`
                            : `<span class='badge badge-pill badge-danger'>Thất bại</span>`
                    return log
                })
            }
        },
        getPaymentInfo: function() {
            if (this.site.payment) {
                this.paymentInfo = JSON.parse(this.site.payment)
            }
        },
        transCoin: async function() {
            let postData = {
                to_user: this.to_user,
                quantity: this.quantity,
                description: this.descriptionTrans
            }
            let data = await userTransCoin(postData)
            if (data.status === 200 && data.success) {
                this.$swal(
                    "Thành Công",
                    "Đã chuyển thành công " +
                        Number(this.quantity).toLocaleString("it-IT") +
                        " đến account " +
                        this.to_user,
                    "success"
                )
                // this.$store.dispatch("USER_GET_INFO")
            } else {
                this.$swal("Lỗi", catchError(data), "error")
            }
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
            if (panel == "history") {
                this.getPaymentcards()
            }
            if (panel == "card") {
                this.getPaymentCard()
            }
        }
    }
}
</script>
