import { request } from "../utils/request"

export function getStatistics(dateFrom, dateTo) {
    return request({
        url: "api/statistical/v2?date_from=" + dateFrom + "&date_to=" + dateTo,
        method: "get"
    })
}
export function getPaymentLogs() {
    return request({
        url: "api/tool-logs?sort_by=desc&action=update",
        method: "get"
    })
}
export function getPaymentMonth() {
    return request({
        url: "api/statistical/statistic-in-month",
        method: "get"
    })
}
export function userTransCoin(data) {
    return request({
        url: "api/users/coin/transaction",
        method: "post",
        data
    })
}
//Auto Card
export function getPaymentCardConfig() {
    return request({
        url: "api/payment-card/config",
        method: "get"
    })
}
export function sedPaymentCard(data) {
    return request({
        url: "api/payment-card/payment",
        method: "post",
        data
    })
}
export function getPaymentCard() {
    return request({
        url: "api/payment-card",
        method: "get"
    })
}
